import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  ADD_VENDER_REQUEST,
  APPROVE_MEMBER_REQUEST,
  ASSIGN_VENDOR_REQUEST,
  DELETE_VENDER_REQUEST,
  GET_INTERESTED_MEMBER_LIST_REQUEST,
  GET_VENDER_DETAILS_REQUEST,
  GET_VENDER_LIST_REQUEST,
  GET_VENDOR_MEMBER_REQUEST,
  REMOVE_IMAGE_REQUEST,
  REMOVE_VENDER_REQUEST,
  UPDATE_VENDER_REQUEST,
} from "./type";
import {
  addVendorSuccess,
  approveMemberSuccess,
  assignVendorSuccess,
  deleteVendorSuccess,
  getInterestedMemberListRequest,
  getInterestedMemberListSuccess,
  getVendorDetailsSuccess,
  getVendorListRequest,
  getVendorListSuccess,
  getVendorMemberRequest,
  getVendorMemberSuccess,
  removeImageSuccess,
  removeVendorSuccess,
  updateVendorRequest,
  updateVendorSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";

function* vendorList({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/vendorList", data);
    if (res.status) {
      yield put(getVendorListSuccess(res.data));
    } else {
      yield put(getVendorListSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* addVendor({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/vendor", data.formData);
    if (res.status) {
      ApiResponseMessage(res.message, SUCCESS);
      yield put(addVendorSuccess(res));
      yield put(getVendorListRequest(data.dispatchData));
    } else {
      yield put(addVendorSuccess(res));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(addVendorSuccess());
    handleApiCallException(error);
  }
}
function* updateVendor({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/editVendor", data.formData);
    if (res.status) {
      ApiResponseMessage(res.message, SUCCESS);
      yield put(updateVendorSuccess(res));
      yield put(getVendorListRequest(data.dispatchData));
    } else {
      yield put(updateVendorSuccess(res));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(updateVendorSuccess());
    handleApiCallException(error);
  }
}
function* changeVendor({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/statusVendor", data.value);
    if (res.status) {
      ApiResponseMessage(res.message, SUCCESS);
      yield put(removeVendorSuccess(res));
      yield put(getVendorListRequest(data.dispatchData));
    } else {
      yield put(removeVendorSuccess(res));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(removeVendorSuccess());
    handleApiCallException(error);
  }
}
function* deleteVendor({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/deleteVendor", data.value);
    if (res.status) {
      ApiResponseMessage(res.message, SUCCESS);
      yield put(deleteVendorSuccess(res));
      yield put(getVendorListRequest(data.dispatchData));
    } else {
      yield put(deleteVendorSuccess(res));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(removeVendorSuccess());
    handleApiCallException(error);
  }
}
function* vendorDetails({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/vendorDetails", data);
    if (res.status) {
      yield put(getVendorDetailsSuccess(res.data));
    } else {
      yield put(getVendorDetailsSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(getVendorDetailsSuccess([]));
    handleApiCallException(error);
  }
}
function* memberDetails({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/intrestedVendorList", data);
    if (res.status) {
      yield put(getInterestedMemberListSuccess(res.data));
    } else {
      yield put(getInterestedMemberListSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(getInterestedMemberListSuccess([]));
    handleApiCallException(error);
  }
}
function* approveRequest({ payload: data }) {
  try {
    const res = yield call(
      invokeApi,
      "post",
      "/vendorInterestStatus",
      data.data
    );
    if (res.status) {
      yield put(approveMemberSuccess(res.data));
      if (data.data?.type === 'members') {
        yield put(getVendorMemberRequest(data.dispatchData));
      } else {
        yield put(getInterestedMemberListRequest(data.dispatchData));
      }
      ApiResponseMessage(res.message, SUCCESS);
    } else {
      yield put(approveMemberSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(approveMemberSuccess([]));
    handleApiCallException(error);
  }
}
function* removeImage({ payload: { data, api } }) {

  try {
    const res = yield call(invokeApi, "post", "/removeImages", data);
    if (res.status) {
      yield put(removeImageSuccess(res.data));
      yield put(updateVendorRequest(api));

    } else {
      yield put(removeImageSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(removeImageSuccess([]));
    handleApiCallException(error);
  }
}
function* memberList({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/intrestedMembersList", data);
    if (res.status) {
      yield put(getVendorMemberSuccess(res.data));
    } else {
      yield put(getVendorMemberSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(getVendorMemberSuccess([]));
    handleApiCallException(error);
  }
}

function* assignVendor({ payload: data }) {
  try {
    const response = yield call(
      invokeApi,
      "post",
      "/allocateVendors",
      data.AssignedValues
    );
    if (response.status) {
      yield put(assignVendorSuccess(response));
      ApiResponseMessage(response.message, SUCCESS);
      yield put(getVendorListRequest(data.dispatchData));
    } else {
      yield put(assignVendorSuccess(response));
      ApiResponseMessage(response.message, DANGER);
      handleApiCallException(response.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* watchVendorRequest() {
  yield takeLatest(GET_VENDER_LIST_REQUEST, vendorList);
  yield takeLatest(ADD_VENDER_REQUEST, addVendor);
  yield takeLatest(UPDATE_VENDER_REQUEST, updateVendor);
  yield takeLatest(REMOVE_VENDER_REQUEST, changeVendor);
  yield takeLatest(DELETE_VENDER_REQUEST, deleteVendor);
  yield takeLatest(GET_VENDER_DETAILS_REQUEST, vendorDetails);
  yield takeLatest(GET_INTERESTED_MEMBER_LIST_REQUEST, memberDetails);
  yield takeLatest(APPROVE_MEMBER_REQUEST, approveRequest);
  yield takeLatest(REMOVE_IMAGE_REQUEST, removeImage);
  yield takeLatest(GET_VENDOR_MEMBER_REQUEST, memberList);
  yield takeLatest(ASSIGN_VENDOR_REQUEST, assignVendor);
}

function* VendorSaga() {
  yield all([fork(watchVendorRequest)]);
}

export default VendorSaga;
