import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  ADD_VENDER_CONTACT_REQUEST,
  DELETE_VENDER_CONTACT_REQUEST,
  GET_VENDER_CONTACT_LIST_REQUEST,
  SYNC_VENDER_CONTACT_REQUEST,
} from "./type";
import {
  addVendorContactsSuccess,
  deleteVendorContactSuccess,
  getVendorContactsListRequest,
  getVendorContactsListSuccess,
  syncVendorContactsSuccess,
} from "./action";
import { handleApiCallException } from "../../shared/action";
import { ApiResponseMessage } from "../../../helper/tosterHelper";
import { DANGER, SUCCESS } from "../../../constant/commonConstants";
import { invokeApi } from "../../../helper/axiosHelper";

function* vendorContact({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/vendorContactList", data);
    if (res.status) {
      yield put(getVendorContactsListSuccess(res.data));
    } else {
      yield put(getVendorContactsListSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* syncVendorContact({ payload: data }) {
  try {
    const res = yield call(invokeApi, "get", "/syncVendorContact");
    if (res.status) {
      yield put(syncVendorContactsSuccess(res.data));
      ApiResponseMessage(res.message, SUCCESS);
      yield put(getVendorContactsListRequest(data));
    } else {
      yield put(syncVendorContactsSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    ApiResponseMessage(error, DANGER);
    yield put(syncVendorContactsSuccess([]));
    handleApiCallException(error);
  }
}

function* addVendorContact({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/addVendorContact", data);
    if (res.status) {
      yield put(addVendorContactsSuccess(res));
      ApiResponseMessage(res.message, SUCCESS);
    } else {
      yield put(addVendorContactsSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    yield put(addVendorContactsSuccess([]));
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* deleteVendorContact({ payload: data }) {
  try {
    const res = yield call(invokeApi, "post", "/deleteVendorContact", data.delete);
    if (res.status) {
      yield put(deleteVendorContactSuccess(res));
      ApiResponseMessage(res.message, SUCCESS);
      yield put(getVendorContactsListRequest(data.dispatch));
    } else {
      yield put(deleteVendorContactSuccess([]));
      ApiResponseMessage(res.message, DANGER);
      handleApiCallException(res.message);
    }
  } catch (error) {
    yield put(deleteVendorContactSuccess([]));
    ApiResponseMessage(error, DANGER);
    handleApiCallException(error);
  }
}

function* watchVendorContactsRequest() {
  yield takeLatest(SYNC_VENDER_CONTACT_REQUEST, syncVendorContact);
  yield takeLatest(GET_VENDER_CONTACT_LIST_REQUEST, vendorContact);
  yield takeLatest(ADD_VENDER_CONTACT_REQUEST, addVendorContact);
  yield takeLatest(DELETE_VENDER_CONTACT_REQUEST, deleteVendorContact);
}

function* VendorContactsSaga() {
  yield all([fork(watchVendorContactsRequest)]);
}

export default VendorContactsSaga;
