
export const addUserInitialState = (values) => {
    let value = values === undefined ? "" : values;
    console.log(value.moduleAccess,"value")
    return {
        email: value?.email,
        phoneNumber: value?.phoneNumber,
        roleId: value?.roleId,
        fullName: value?.fullName,
        userId: value?.id,
        vendorAccess: value?.vendorAccess,
        addVendorContacts: value?.moduleAccess?.addVendorContacts,
        editVendorContacts:value?.moduleAccess?.editVendorContacts,
    };
};
