import * as type from "./type";

export const syncVendorContactsRequest = (data) => {
  return {
    type: type.SYNC_VENDER_CONTACT_REQUEST,
    payload: data,
  };
};
export const syncVendorContactsSuccess = (data) => {
  return {
    type: type.SYNC_VENDER_CONTACT_SUCCESS,
    payload: data,
  };
};
export const getVendorContactsListRequest = (data) => {
  return {
    type: type.GET_VENDER_CONTACT_LIST_REQUEST,
    payload: data,
  };
};
export const getVendorContactsListSuccess = (data) => {
  return {
    type: type.GET_VENDER_CONTACT_LIST_SUCCESS,
    payload: data,
  };
};
export const addVendorContactsRequest = (data) => {
  return {
    type: type.ADD_VENDER_CONTACT_REQUEST,
    payload: data,

  };
};
export const addVendorContactsSuccess = (data) => {
  return {
    type: type.ADD_VENDER_CONTACT_SUCCESS,
    payload: data,
  };
};
export const deleteVendorContactRequest = (data) => {
  return {
    type: type.DELETE_VENDER_CONTACT_REQUEST,
    payload: data,

  };
};
export const deleteVendorContactSuccess = (data) => {
  return {
    type: type.DELETE_VENDER_CONTACT_SUCCESS,
    payload: data,
  };
};
