import * as type from "./type";

const initialState = {
  syncVendorContacts: null,
  vendorContacts: null,
  addVendorContacts: null,
  deleteVendorContact: null,
  createVendorContact: null
};

const VendorContactsReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.SYNC_VENDER_CONTACT_REQUEST:
      return { ...state, syncVendorContacts: null };
    case type.SYNC_VENDER_CONTACT_SUCCESS:
      return { ...state, syncVendorContacts: action.payload };
    case type.GET_VENDER_CONTACT_LIST_REQUEST:
      return { ...state, vendorContacts: null };
    case type.GET_VENDER_CONTACT_LIST_SUCCESS:
      return { ...state, vendorContacts: action.payload };
    case type.ADD_VENDER_CONTACT_REQUEST:
      return { ...state, addVendorContacts: null };
    case type.ADD_VENDER_CONTACT_SUCCESS:
      return { ...state, addVendorContacts: action.payload };
    case type.DELETE_VENDER_CONTACT_REQUEST:
      return { ...state, deleteVendorContact: null };
    case type.DELETE_VENDER_CONTACT_SUCCESS:
      return { ...state, deleteVendorContact: action.payload };
    default:
      return {
        ...state,
      };
  }
};
export default VendorContactsReducer;
