import React, { useState } from "react";
import { connect } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    Col,
    FormGroup,
    Input,
    Label,
    Row,
} from "reactstrap";
import { CloseSvg } from "../../../components/CommonSvg";
function Vendorfilter({ handelShowFIlter, useFilter }) {
    const { setSearchState, setSearchCity, setSearchVendor, setIsFilter } = useFilter;
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [vendor, setVendor] = useState("");
    return (
        <>
            <Card className="filter_section card">
                <CardBody>
                    <h6 className="separate-head">Filter</h6>
                    <>
                        <form
                            className="hra-create"
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <Row>
                                <Col sm={12} className="no-floating-label">
                                    <Row>
                                        <FormGroup className="col mb-0">
                                            <Input
                                                id="State"
                                                onChange={(e) => {
                                                    setState(e.target.value);
                                                    if (e.target.value?.length <= 0) {
                                                        setState("");
                                                        setSearchState("");
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        setSearchState(state);
                                                    }
                                                }}
                                                type="search"
                                            />
                                            <Label className="form-label" htmlFor="assignStatus">
                                                State
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className="col mb-0">
                                            <Input
                                                id="Cities"
                                                onChange={(e) => {
                                                    setCity(e.target.value);
                                                    if (e.target.value?.length <= 0) {
                                                        setCity("");
                                                        setSearchCity("");
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        setSearchCity(city);
                                                    }
                                                }}
                                                type="search"
                                            />
                                            <Label className="form-label" htmlFor="Cities">
                                                Territory and Cities
                                            </Label>
                                        </FormGroup>
                                        <FormGroup className="col mb-0">
                                            <Input
                                                id="VendorName"
                                                onChange={(e) => {
                                                    setVendor(e.target.value);
                                                    if (e.target.value?.length <= 0) {
                                                        setVendor("");
                                                        setSearchVendor("");
                                                    }
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        setSearchVendor(vendor);
                                                    }
                                                }}
                                                type="search"
                                            />
                                            <Label className="form-label" htmlFor="VendorName">
                                                Vendor Name
                                            </Label>
                                        </FormGroup>
                                    </Row>
                                    {/* <Button
                                        className="my-tooltip cancel_filter"
                                        data-tooltip-content={"Close filter"}
                                        color="link"
                                        onClick={handelShowFIlter}
                                    >
                                        <CloseSvg />
                                    </Button> */}
                                </Col>
                            </Row>
                        </form>
                    </>
                </CardBody>
            </Card>
        </>
    );
}
const mapStateToProps = ({ }) => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Vendorfilter);
