import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Date_Svg,
  EmailSvg2,
  Phone_Svg,
  RepresentativeSvg,
  RightSvg,
  ShopSvg,
  ShortDownSvg,
  ShortUpSvg,
  UserSvg,
} from "../../../components/CommonSvg";
import Nodata from "../../../assets/images/no_data_found001.webp";

import { Button } from "reactstrap";
import {
  approveMemberRequest,
  getVendorMemberRequest,
} from "../../../redux/admin/vendor/action";
import CommonPagination from "../../../components/Common/TableView/CommonPagination";
import { commonConfirmBox } from "../../../helper/messagehelpers";
function MemberList({
  getVendorMemberRequest,
  values,
  members,
  approveMember,
  totalRecords,
  c,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("DESC");
  const [orderKey, setOrderKey] = useState("createdDate");
  const dispatchData = {
    searchText: values.search,
    status: values.filter,
    pagination: {
      limit: dataPerPage,
      page: currentPage,
      orderKey: orderKey,
      orderBy: orderBy,
    },
  };
  useEffect(() => {
    if (values.search) {
      setCurrentPage(1);
    }
  }, [values.search]);
  useEffect(() => {
    if ((currentPage, dataPerPage)) {
      getVendorMemberRequest(dispatchData);
    }
  }, [currentPage, dataPerPage, values.search, values.filter, orderBy]);
  const onChangeOrderBy = (data) => {
    if (orderBy == "ASC") {
      setOrderBy("DESC");
      setOrderKey(data);
    } else {
      setOrderKey(data);
      setOrderBy("ASC");
    }
  };
  return (
    <div>
      <ul className="vendor_member_row member_row_head">
        <li className="store_hra_id_name">
          <span>HRA Id</span>
          <Button
            className="btn"
            onClick={() => onChangeOrderBy("hraMemberId")}
          >
            {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
          </Button>
        </li>
        <li className="store-hra-contact member_user_name">
          <span>Member</span>
          <Button className="btn" onClick={() => onChangeOrderBy("firstName")}>
            {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
          </Button>
        </li>
        <li className="store-hra-contact">
          <span>Phone No.</span>
          {/* <Button className="btn" onClick={() => onChangeOrderBy("phoneNumberMobileNumber")}>
            {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
          </Button> */}
        </li>
        <li className="store-hra-contact">
          <span>Vendor</span>
          <Button className="btn" onClick={() => onChangeOrderBy("name")}>
            {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
          </Button>
        </li>
        <li className="store-hra-contact">
          <span>Representative</span>
          <Button
            className="btn"
            onClick={() => onChangeOrderBy("managerTerritory")}
          >
            {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
          </Button>
        </li>
        <li className="store-hra-contact member_date_field">
          <span>Date</span>
          <Button
            className="btn"
            onClick={() => onChangeOrderBy("createdDate")}
          >
            {orderBy == "ASC" ? <ShortUpSvg /> : <ShortDownSvg />}
          </Button>
        </li>
        <li className="store-hra-action">
          <span></span>
        </li>
      </ul>
      {members.map((user) => {
        return (
          <ul
            className={
              user.schedularStatus
                ? "vendor_member_row rejected_row"
                : user.status
                ? "vendor_member_row approverd_row"
                : "vendor_member_row "
            }
          >
            <li className="store_hra_id">
              <span>{user.hraMemberId}</span>
            </li>
            <li className="member_user_name">
              <p>
                <UserSvg />
                <span className="user_details_field">
                  {user.member.firstName + " " + user.member.lastName}
                  <p>{user.member.emailAddress}</p>
                </span>
              </p>
            </li>
            {/* <li className="store-hra-mail">
              <p>
                <EmailSvg2 />
                <span>{user.member.emailAddress}</span>
              </p>
            </li> */}
            <li className="store-hra-contact">
              <p>
                <Phone_Svg />
                <span>{user.member.phoneNumberMobileNumber}</span>
              </p>
            </li>
            <li className="store-hra-contact">
              <p>
                <ShopSvg />
                <span>{user.vendor?.name}</span>
              </p>
            </li>
            <li className="store-hra-contact representative_field">
              <p>
                <RepresentativeSvg />
                <span>{user.member?.managerTerritory}</span>
              </p>
            </li>
            <li className="store-hra-contact member_date_field">
              <p>
                <Date_Svg />
                <span>{user.createdDate}</span>
              </p>
            </li>
            <li className="store-hra-action">
              {user.status ? (
                <Button color="primary" className="approve_btn approved_show" disabled={user.vendor.isDelete}>
                  <RightSvg />
                  Approved
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="approve_btn"
                  onClick={async () => {
                    const response = await commonConfirmBox(
                      `Are you sure you want to approve this member?`
                    );
                    if (response) {
                      approveMember({
                        data: {
                          vendorId: user.vendorId,
                          status: true,
                          hraMemberId: user.hraMemberId,
                          type: "members",
                        },
                        dispatchData,
                      });
                    }
                  }}
                  disabled={user.vendor.isDelete}
                >
                  <RightSvg />
                  Approve
                </Button>
              )}
              {/* <Button className="reject_btn">
                  <CloseSvg/>
                  Reject
                </Button> */}
            </li>
          </ul>
        );
      })}
      {totalRecords > 0 ? (
        <CommonPagination
          dataPerPage={dataPerPage}
          totalData={totalRecords}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setDataPerPage={setDataPerPage}
        />
      ) : (
        <div className="d-flex justify-content-center">
          <img alt="NO Data Found" className="no_data_found" src={Nodata} />
        </div>
      )}
    </div>
  );
}
const mapStateToProps = ({ vendor }) => {
  let members = vendor.members?.list?.length > 0 ? vendor.members.list : [];
  let totalRecords = vendor.members?.totalRecords;
  return { members, totalRecords };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVendorMemberRequest: (data) => dispatch(getVendorMemberRequest(data)),
    approveMember: (data) => dispatch(approveMemberRequest(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MemberList);
