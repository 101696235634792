import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { Row, Col, Label, FormGroup, Button } from "reactstrap";
import logosm from "../../../../src/assets/images/logo-dark.webp";
import SimpleReactValidator from "simple-react-validator";
import { useRef } from "react";
import { connect } from "react-redux";
import login_bg from "../../../../src/assets/images/memberportal.webp";
import { getMemberLoginRequest } from "../../../redux/frontend/auth/login/action";
import { useEffect } from "react";
import { __t } from "../../../i18n/translator";
import { PasseyeSvg, PasseyeopenSvg } from "../../../components/CommonSvg";

function LoginPage(props) {
  const { memberLoginReq, MemberLoginStatus } = props;
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const handlePassword = () => {
    setShowPassword(!showPassword);
  };
  const storedUsername = localStorage.getItem("rememberedUsername");
  const storedPassword = localStorage.getItem("rememberedPassword");
  let LoginInitialValues = {
    hraMemberId: storedUsername || "",
    password: storedPassword || "",
  };

  const validator = useRef(new SimpleReactValidator());
  const handleSubmit = async (values) => {
    if (validator.current.allValid()) {
      memberLoginReq({
        hraMemberId: values.hraMemberId,
        password: values.password,
      });
      if (values.rememberMe) {
        localStorage.setItem("rememberedUsername", values.hraMemberId);
        localStorage.setItem("rememberedPassword", values.password);
      } else {
        localStorage.removeItem("rememberedUsername");
        localStorage.removeItem("rememberedPassword");
      }
    } else {
      validator.current.showMessages();
    }
  };
  useEffect(() => {
    if (
      MemberLoginStatus == true &&
      MemberLoginStatus !== undefined &&
      localStorage.getItem("memberAccessToken")
    ) {
      navigate("/home");
    }
  }, [MemberLoginStatus]);
  useEffect(() => {
    if (localStorage.getItem("memberAccessToken")) {
      navigate("/home");
    }
  }, [MemberLoginStatus]);
  return (
    <>
      <div className="front_login_page">
        <div className="login_box">
          <div className="login_bg_fornt">
            <img alt="login_bg" src={login_bg} />
          </div>
          <div className="login_field_fornt">
            <Row>
              <Formik
                initialValues={LoginInitialValues}
                enableReinitialize={false}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue }) => (
                  <Col className="login-form">
                    <div className="loging-head">
                      <img alt="Logo" src={logosm} />
                      <h2>Member Login</h2>
                    </div>
                    <Form className="form-horizontal form-login row login_member">
                      <FormGroup className="col-12 sticky_label">
                        <Field
                          id="hraMemberId"
                          name="hraMemberId"
                          className="form-control"
                          placeholder="HRAXXXX"
                          type="text"
                          onChange={(value) => {
                            setFieldValue("hraMemberId", value.target.value);
                            // setUsername(value.target.value)
                          }}
                          value={values.hraMemberId}
                        />
                        <Label className="form-label" htmlFor="hraMemberId">
                          Login Name{" "}
                          <span className="text-danger required">*</span>
                        </Label>
                        {validator.current.message(
                          __t("validations.auth.hraMemberId"),
                          values.hraMemberId,

                          "required",
                          {
                            messages: {
                              required: __t("validations.auth.hraMemberId"),
                            },
                          }
                        )}
                      </FormGroup>
                      <FormGroup className="col-12 sticky_label">
                        <Field
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          autocomplete="off"
                          maxLength={99}
                          minLength={6}
                          onChange={(value) => {
                            setFieldValue("password", value.target.value);
                            // setPassword(value.target.value)
                          }}
                          value={values.password}
                        />
                        <Label className="form-label" htmlFor="password">
                          Password
                          <span className="text-danger required">*</span>
                        </Label>
                        <Button
                          color="link"
                          className="pass_eye_btn"
                          title="Login"
                          type="button"
                          onClick={handlePassword}
                        >
                          {showPassword ? <PasseyeopenSvg /> : <PasseyeSvg />}
                        </Button>
                        {validator.current.message(
                          __t("validations.auth.password"),
                          values.password,
                          "required",
                          {
                            messages: {
                              required: __t("validations.auth.password"),
                            },
                          }
                        )}
                      </FormGroup>
                      <FormGroup className="col-12 remember_me">
                        <Field
                          type="checkbox"
                          name="rememberMe"
                          id="rememberMe"
                          checked={values.rememberMe}
                        />
                        <Label className="form-label" htmlFor="rememberMe">
                          Remember me
                        </Label>
                      </FormGroup>
                      <div className="forgot_btn">
                        {/* <Link
                          to="/forgotlogin"
                          className="d-inline-block"
                          title="Forgot login ?"
                        >
                          Forgot login ?
                        </Link> */}
                        <Link
                          to="/forgotPassword"
                          className="d-inline-block"
                          title="Reset Password"
                        >
                          Reset Password
                        </Link>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <Button
                          type="submit"
                          color="primary"
                          className="w-auto"
                          title="Login"
                        >
                          Login
                        </Button>
                      </div>
                      <div className="become_link">
                        <span>Don’t have an account?</span>
                        {/* <Link
                          to="/inquiry"
                          className="d-inline-block"
                          title="Become a member ?"
                        >
                          Become a member ?
                        </Link> */}
                        <Link
                          to="https://www.jotform.com/sign/243439194445059/invite/01jeqmy2g00cd199006bb262c6"
                          className="d-inline-block"
                          title="Become a member ?"
                          target="_blank"
                        >
                          Become a member ?
                        </Link>
                      </div>
                    </Form>
                    <Link
                      color="link"
                      to="https://www.hraga.com/memberportal"
                      // to="/inquiry"
                      target="_black"
                      className="help_btn"
                    >
                      Help or Need Assistance
                    </Link>
                    <div className="policy-links">
                      <Link
                        to="https://www.hraga.com/terms-of-service"
                        className="d-inline-block"
                        target="_blank"
                        title="Terms of Service"
                      >
                        Terms of Service
                      </Link>
                      <Link
                        to="https://www.hraga.com/mbr-app-privacy-policy"
                        className="d-inline-block"
                        target="_blank"
                        title="Privacy Policy"
                      >
                        Privacy Policy
                      </Link>
                    </div>
                  </Col>
                )}
              </Formik>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = ({ MemberLogin }) => {
  let MemberLoginStatus =
    MemberLogin?.loginUser?.status && MemberLogin.loginUser.status;
  return { MemberLoginStatus };
};
const mapDispatchToProps = (dispatch) => {
  return {
    memberLoginReq: (crd) => dispatch(getMemberLoginRequest(crd)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
