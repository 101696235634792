
import { jwtDecode } from "jwt-decode";
export const getModuleAccess = () => {
  let moduleAccess = localStorage.getItem("moduleAccess");
  try {
    const decoded = jwtDecode(moduleAccess, '3BA1BB72EF2D8B8BBBB7BA83F5E27');
    return decoded
  } catch (error) {
  }
};

export const getValueByKey = (key) => {
  return localStorage.getItem(key);
};
export const setValueByKey = (key, value) => {
  return localStorage.setItem(key, value);
}
export const removeValueByKey = (key) => {
  return localStorage.removeItem(key);
}
export const getUserName = () => {
  return localStorage.getItem("fullName");
};
export const removeLoggedInUserDetails = () => {
  localStorage.removeItem("email");
  localStorage.removeItem("user");
  localStorage.removeItem("roleId");
  localStorage.removeItem("AssignPromoCopy");
  localStorage.removeItem("adminAccessToken");
  localStorage.removeItem("fullName");
};
export const removeLoggedInMemberDetails = () => {
  localStorage.removeItem("storeNameRegular");
  localStorage.removeItem("phoneNumber");
  localStorage.removeItem("accountName");
  localStorage.removeItem("memberEmail");
  localStorage.removeItem("firstName");
  localStorage.removeItem("lastName");
  localStorage.removeItem("memberAccessToken");
  localStorage.removeItem("hraMemberId");
};

export const setLoginUserData = (data) => {
  localStorage.setItem("email", JSON.stringify(data.data.user.email));
  localStorage.setItem("user", data.data.user.userId);
  localStorage.setItem("lastSync", data.data.user?.storeLastSync?.createdDate);
  localStorage.setItem("roleId", JSON.stringify(data.data.user.roleId));
  localStorage.setItem("adminAccessToken", data?.data?.accessToken);
  localStorage.setItem("favouriteMenu", data?.data?.user?.favouriteMenu);
  localStorage.setItem("fullName", data?.data?.user?.fullName);
  localStorage.setItem("vendorAccess", data?.data?.user?.vendorAccess);
  localStorage.setItem("moduleAccess", data?.data?.moduleAccess);
};
export const setLoginMemberData = (data) => {
  localStorage.setItem("storeNameRegular", data?.data?.user?.storeNameRegular);
  localStorage.setItem("phoneNumber", data?.data?.user?.phoneNumber);
  localStorage.setItem("accountName", data?.data?.user?.accountName);
  localStorage.setItem("memberEmail", JSON.stringify(data.data.user.email));
  localStorage.setItem("firstName", JSON.stringify(data.data.user.firstName));
  localStorage.setItem("lastName", JSON.stringify(data.data.user.lastName));
  localStorage.setItem("memberAccessToken", data?.data?.accessToken && data.data.accessToken);
  localStorage.setItem("mailingStreetAddress", data.data.user.mailingStreetAddress + ", " + data.data.user.city + ", " + data.data.user.state + ", " + data.data.user.mailingZip);
  localStorage.setItem("hraMemberId", JSON.stringify(data.data.user.hraMemberId));
  localStorage.setItem("parentHraMemberId", JSON.stringify(data.data.user.hraMemberId));

};
export function getFileNameFromUrl(url) {
  const segments = url?.split("/");
  let fileName = segments?.pop();
  fileName = fileName?.replace(/%/g, "");
  return fileName;
}

export function removeZohoToken() {
  localStorage.removeItem("zohoTokenTime");
  localStorage.removeItem("zohoToken");
}

