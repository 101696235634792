import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useLocation } from "react-router-dom";

export default function CommonTable(props) {
  // const location = useLocation();

  const {
    data,
    customClass,
    responsiveClass,
    columns,
    handleScroll,
    rowClassName,
    tableId,
    rowEvents,onTableChange,remote
  } = props;
  const onRowClick = {
    onClick: rowEvents ? rowEvents : () => {},
  };
  return (
    <>
      <div
        className={responsiveClass ? responsiveClass : "" + " table-responsive"}
        onScroll={handleScroll}
      >
        <BootstrapTable
          id={tableId ? tableId : "myTable"}
          classes={customClass ? customClass : "" + " small table-sm"}
          striped={true}
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          bordered={true}
          rowEvents={onRowClick}
          onTableChange={onTableChange}
          remote={remote}
          rowClasses={
            rowClassName
            // ? rowClassName
            // : (row, rowIndex) =>
            //     rowIndex === data?.length - 1 &&
            //         (location.pathname === "/rebate" || location.pathname === "/admin/rebate")
            //       ? "rebate-detail-total"
            //       : ""
          }
        />
      </div>
    </>
  );
}
